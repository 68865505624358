import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import Footer from '../components/FooterSite'
import ctaEscola from '../images/escolas.png'
import ArrowDown from '../images/ArrowDown.png'
import Item1 from '../images/item-01.png'
import Item2 from '../images/item-02.png'
import Item3 from '../images/item-04.png'
import Item4 from '../images/item-04.png'
import Livro1 from '../images/livro-1.png'
import Livro2 from '../images/livro-2.png'
import InfoGrafico from '../images/comofunciona.svg'
import InfoApoio from '../images/info-apoio.png'
import InfoApoioMobile from '../images/info-apoio-mobile.png'

import { globalStyles } from '../components/layout.styles'

import {
  container,
  content_about,
  content_2,
  about,
  itemConteudo,
  contentApoio,
  comoFunciona,
  desktop,
  mobile,
  contentApoioMobile
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Veredas - Caminho alternativo através do qual é possível chegar mais rápido a um determinado destino." />
    <NavBar location={props.location} />
    <div>
      <div css={about}>
        <div style={{ justifyContent: 'center', display: 'flex', marginTop: 40 }}>
          <img src={ctaEscola} alt='/' />
        </div>
        <div css={container}>
          <div css={content_about} style={{ marginTop: 40 }}>
            <p>Todos sabemos que a educação atualmente passa por grandes transformações e enfrenta desafios maiores ainda: desengajamento dos pais e alunos com a escola, defasagem de conteúdos em relação às suas realidades, emergência de novas habilidades para o futuro que nos espera... Buscando uma experiência de aprendizado real que desse conta desses desafios, o Instituto Vertere criou a Sapientia – a Olimpíada do Futuro. Nela, nós convidamos os alunos a investigar a realidade presente e futura baseados em duas grandes referências: a Agenda 2030 da ONU – proposta das Nações Unidas para mudar e melhorar o mundo nos próximos 10 anos – e no já célebre 21 lições para o século XXI do pensador israelense Yuval Noah Harari. Enquanto Harari nos questiona, a Agenda 2030 nos convoca – são dois guias que fornecem subsídios para todos nós pensarmos e agirmos diante das principais problemáticas do nosso tempo: os desafios da tecnologia, as ameaças ambientais, as transformações produtivas, os problemas sociais. Assim, nossa olimpíada partiu da investigação teórica para avançar para a elaboração de projetos autorais dos alunos com objetivo de alterar a sua realidade local – escola, bairro, cidade, estado. Os resultados foram incríveis! Veja os resultados em link dos projetos da Sapientia 2019.</p>
            <p>O que conseguimos nesse processo? Aproximar a realidade “lá fora” do mundo da escola, e levar a escola para agir no mundo “lá fora”, trazendo as questões mais relevantes da contemporaneidade para pensar os conteúdos escolares, usando esses conhecimentos somados para tentar transformar o mundo. Agora, nós queremos levar essa experiência para dentro da sua escola!</p>
            <p>O projeto Escola para o Futuro convida a sua escola e seus alunos para um projeto conjunto de desenvolvimento de novas práticas de aprendizado na sua escola. Como funciona? Partindo do estudo das duas obras de referências acima citadas – a Agenda 2030 e as 21 Lições para o século XXI -, o projeto avança para oficinas de Design Thinking, nas quais alunos, professores e equipe pedagógica vão conectar os assuntos da sua realidade atual com os conteúdos trabalhados em sala. O objetivo? Produzir novas metodologias de aprendizado (em sala de aula ou fora dela), novas temáticas de estudo (na grade curricular ou no contraturno), novas práticas de trabalho (entre alunos e entre alunos e professores), e assim por diante. O que conseguimos com isso? Em primeiro lugar, uma transformação genuína, criativa e colaborativa dos processos de ensino e aprendizagem da escola; o engajamento de alunos, professores e equipe pedagógica em um trabalho conjunto de criação de novas soluções, autorais e adaptadas às realidades locais; e, por fim, um aprofundamento da relevância dos conteúdos estudados em sala ao conectá-los com as demandas fundamentais do nosso presente e do nosso futuro.</p>  
          </div>
        </div>
      </div>
      <div css={container}>
        <div css={content_2} style={{ marginTop: 16 }}>
          <h2>COMO FUNCIONA?</h2>
          <div css={desktop}>
            <img src={InfoGrafico} alt='/' style={{ width: 1013 }} />
          </div>
          <div css={[comoFunciona, mobile]}>
            <img src={Item1} alt='/' />
            <p>Leitura e estudo do livro “21 lições para o século XXI” e da Agenda 2030 da ONU</p>
            <img src={ArrowDown} style={{ marginBottom: 12 }} alt='/' />
            <img src={Item2} alt='/' />
            <p>Exercícios sobre habilidades do século XXI e pilares de empregabilidade no futuro</p>
            <img src={ArrowDown} style={{ marginBottom: 12 }} alt='/' />
            <img src={Item3} alt='/' />
            <p>Oficinas de Design Thinking para pensar as principais questões concernentes à realidade da escola</p>
            <img src={ArrowDown} style={{ marginBottom: 12 }} alt='/' />
            <img src={Item4} alt='/' />
            <p>Avaliação dos resultados e publicação das melhores soluções pensadas</p>
          </div>
        </div>
        <div css={[content_2, desktop]} style={{ marginTop: 16 }}>
          <h2>CONTEÚDO DE APOIO</h2>
          <div css={itemConteudo} style={{ marginTop: 0 }}>
            <img src={Livro1} alt='/' />
            <div css={contentApoio}>
              <p>Em “21 lições para o século 21”, Yuval Harari nos conduz de forma leve e cativante em uma jornada pelos assuntos mais relevantes da atualidade. O livro será a leitura base para guiar os alunos no trabalho que farão sobre o papel da escola na preparação dos talentos que o mundo precisa.</p>
            </div>
          </div>
          <div css={itemConteudo} style={{ marginTop: 40 }}>
            <img src={Livro2} alt='/' />
            <div css={contentApoio}>
              <p>O programa segue um plano estruturado de design thinking, resumido em um livro digital que os alunos e professores receberão. O trabalho com os alunos está organizado em 10 módulos semanais, mas o programa permite ajustes para se adequar ao calendário da escola. estruturado de design thinking, resumido em um livro digital que os alunos e professores receberão.</p>
            </div>
          </div>
        </div>
        <div css={[content_2, mobile]} style={{ marginTop: 16 }}>
          <h2>CONTEÚDO DE APOIO</h2>
          <div><img src={Livro1} alt='/' /></div>
          <div css={contentApoioMobile}>            
            <p>Em “21 lições para o século 21”, Yuval Harari nos conduz de forma leve e cativante em uma jornada pelos assuntos mais relevantes da atualidade. O livro será a leitura base para guiar os alunos no trabalho que farão sobre o papel da escola na preparação dos talentos que o mundo precisa.</p>
          </div>
          <div style={{ marginTop: 40 }}><img src={Livro2} alt='/' /></div>
          <div css={contentApoioMobile}>            
            <p>O programa segue um plano estruturado de design thinking, resumido em um livro digital que os alunos e professores receberão. O trabalho com os alunos está organizado em 10 módulos semanais, mas o programa permite ajustes para se adequar ao calendário da escola. estruturado de design thinking, resumido em um livro digital que os alunos e professores receberão.</p>
          </div>
        </div>
        <div css={desktop} style={{ marginTop: 40 }}>
          <img src={InfoApoio} alt='/' />
        </div>
        <div css={mobile} style={{ padding: 16 }} >
          <img src={InfoApoioMobile} alt='/' style={{ width: '100%' }} />
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
